import React, { useEffect } from "react";


const Info = () => {
  useEffect(() => {
    document.body.classList.add("scroll");

    return () => {
      document.body.classList.remove("scroll");
    };
  }, []);

  useEffect(() => {
    // Add a class to the body element to prevent Reader Mode
    document.body.classList.add("no-reader-mode");

    return () => {
      // Remove the class when the component unmounts
      document.body.classList.remove("no-reader-mode");
    };
  }, []);


  return (
    <div className="about">
      <div className="block-1">
        <div className="info">
        Seungyong Moon is a Seoul-based design studio that values <span className="italic">open communication</span> and <span className="italic">diverse perspectives</span>. With an MA in graphic design from the Sandberg Instituut Amsterdam, I specialize in creating publications, print materials, website designs, visual identities, exhibition graphics, 3D, and motion design for collaborators, clients, artists, and institutions.
        <br/><br/>
        My priority is understanding my clients' visions to ensure their successful realization. I'm deeply interested in the impact of graphic design and feel a strong sense of responsibility for its influence. My process is informed by research on graphics and social phenomena, resulting in socially relevant designs. Committed to visualizing unvisualized stories, I approach each project with curiosity, embracing complexity and finding value in both logic and humor.        <br/><br/>
Previously, I was Art Director at <span className="italic">Hypebeast</span>, <span className="italic">ARR</span> and <span className="italic">CASETiFY</span>.        </div>
        <div className="teach">
          <span className="intl">Workshops & Lectures:</span>
          <div className="school">
            ArtEZ Institute of the Arts, Willem de Kooning Academie, Hong Kong
            Baptist University, Hanyang University, Hongik University, Silla
            University, Kyonggi University, Toffie Pop Culture Festival.
          </div>
        </div>
        <div className="contract">
          <span className="intl">Contact:</span>
          <a
            href="mailto:seungyongmoon@gmail.com"
            target="_self"
            className="btn-mail"
          >
            seungyongmoon@gmail.com
          </a>
        </div>
        <div className="base">
          <span className="intl">Based In:</span>
          <span className="based">Seoul, KR (GMT+9)</span>
        </div>
        <div className="colophon">
          <div className="typeface">
            <span className="intl">Typeface:</span>
            <span className="text">Times Dot</span>
          </div>
          <div className="development">
            <span className="intl">Web Development:</span>
            <span className="text">Seungyong Moon</span>
          </div>
        </div>
      </div>
      <div className="block-2">
        <div className="colab">
          <span className="intl">Selected Clients & Collabs:</span>
          <span>Hypebeast</span>
          <span>Hypebae</span>
          <span>Hypemaker</span>
          <span>CASETiFY</span>
          <span>Gentle Monster</span>
          <span>Meta Open Arts</span>
          <span>Adidas</span>
          <span>Reebok</span>
          <span>Converse</span>
          <span>SCMP</span>
          <span>Tai Kwun</span>
          <span>Casco Art Institute</span>
          <span>NBA</span>
          <span>DHL</span>
          <span>Line Friends</span>
          <span>Neighborhood</span>
          <span>Parasite</span>
          <span>Stranger Things</span>
          <span>Bristol</span>
          <span>HEINZ</span>
          <span>Louvre Museum</span>
          <span>BTS</span>
          <span>Bruce Lee</span>
          {/* <span>Yu Nagaba</span> */}
          <span>Disney</span>
          <span>One Piece</span>
          {/* <span>POKÉMON</span> */}
          <span>NBDKNW</span>
          {/* <span>Kaist</span> */}

        </div>
      </div>
    </div>
  );
};

export default Info;
