import React, { useRef } from "react";

const Video = ({ src, poster }) => {
    const vidRef = useRef(null);
  
    return (
      <video
        ref={vidRef}
        src={src}
        poster={poster}
        // data-flickity-lazyload
        // data-flickity-lazyload-once
        // data-flickity-lazyload-video
        muted
        autoPlay
        playsInline
      />
    );
  };

export default Video;