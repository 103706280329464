import React, { useState, useEffect, useRef } from "react";
import Flickity from "react-flickity-component";
import "flickity-as-nav-for";
import "flickity/dist/flickity.min.css";
import Video from "../components/Video";
import Image from "../components/Image";

const Home = () => {
  const vidRef = useRef(null);
  const [isLoaded, setLoaded] = useState(false);
  const flRef = useRef(null);
  const flRef2 = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [imageCount, setImageCount] = useState(0);
  const totalImages = 66; // Adjust this value based on the number of images


  const handleImageLoad = () => {
    setImageCount(prevCount => prevCount + 1);
  };

  useEffect(() => {
    if (imageCount === totalImages) {
      setLoaded(true);
    }
  }, [imageCount]);

  let Main = [ 
      <div key="1">
        <Video key="1" src="./img/1.mp4" poster="./img/half/1.webp" />
      </div>,
      <div key="2">
        <Video key="2" src="./img/2.mp4" poster="./img//half/2.webp" />
      </div>,
      <div key="3">
        <Image
          key="3"
          src="./img/3.webp"
          srcset="./img/half/3.webp 480w, ./img/3.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="cremaster"
        />
      </div>,
      <div key="4">
        <Video key="4" src="./img/4.mp4" poster="./img//half/4.webp" />
      </div>,
      <div key="5">
        <Image
          key="5"
          src="./img/5.webp"
          srcset="./img/half/5.webp 480w, ./img/5.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="danja guznz"
        />
      </div>,
      <div key="6">
        <Image
          key="6"
          src="./img/6.webp"
          srcset="./img/half/6.webp 480w, ./img/6.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="wiki"
        />
      </div>,
      <div key="7">
        <Image
          key="7"
          src="./img/7.webp"
          srcset="./img/half/7.webp 480w, ./img/7.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="wet paint"
        />
      </div>,
      <div key="8">
        <Image
          key="8"
          src="./img/8.webp"
          srcset="./img/half/8.webp 480w, ./img/8.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="label collection"
        />
      </div>,
      <div key="9">
        <Image
          key="9"
          src="./img/9.webp"
          srcset="./img/half/9.webp 480w, ./img/9.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="freka"
        />
      </div>,
      <div key="10">
        <Image
          key="10"
          src="./img/10.webp"
          srcset="./img/half/10.webp 480w, ./img/10.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="ethos"
        />
      </div>,
      <div key="11">
        <Video key="11" src="./img/11.mp4" poster="./img//half/11.webp" />
      </div>,
      <div key="12">
        <Image
          key="12"
          src="./img/12.webp"
          srcset="./img/half/12.webp 480w, ./img/12.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="bristol"
        />
      </div>,
      <div key="13">
        <Video key="13" src="./img/13.mp4" poster="./img//half/13.webp" />
      </div>,
      <div key="14">
        <Video key="14" src="./img/14.mp4" poster="./img//half/14.webp" />
      </div>,
      <div key="14_5">
        <Image
          key="14_5"
          src="./img/14_5.webp"
          srcset="./img/half/14_5.webp 480w, ./img/14_5.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="doom"
        />
      </div>,
      <div key="15">
        <Image
          key="15"
          src="./img/15.webp"
          srcset="./img/half/15.webp 480w, ./img/15.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="yohji yamamoto"
        />
      </div>,
      <div key="16">
        <Image
          key="16"
          src="./img/16.webp"
          srcset="./img/half/16.webp 480w, ./img/16.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="club c"
        />
      </div>,
      <div key="17">
        <Image
          key="17"
          src="./img/17.webp"
          srcset="./img/half/17.webp 480w, ./img/17.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="mre"
        />
      </div>,
      <div key="18">
        <Video key="18" src="./img/18.mp4" poster="./img//half/18.webp" />
      </div>,
      <div key="19">
        <Image
          key="19"
          src="./img/19.webp"
          srcset="./img/half/19.webp 480w, ./img/19.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="label collection"
        />
      </div>,
      <div key="27">
        <Video key="27" src="./img/27.mp4" poster="./img//half/27.webp" />
      </div>,
      <div key="20_5">
        <Video key="20_5" src="./img/20_5.mp4" poster="./img/20_5.webp" />
      </div>,
      <div key="20">
        <Video key="20" src="./img/20.mp4" poster="./img//half/20.webp" />
      </div>,
      <div key="21">
        <Video key="21" src="./img/21.mp4" poster="./img//half/21.webp" />
      </div>,
      <div key="22">
        <Image
          key="22"
          src="./img/22.webp"
          srcset="./img/half/22.webp 480w, ./img/22.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="nbdknw"
        />
      </div>,
      <div key="22_5">
        <Image
          key="22_5"
          src="./img/22_5.webp"
          srcset="./img/half/22_5.webp 480w, ./img/22_5.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="nbdknw"
        />
      </div>,
      <div key="23">
        <Image
          key="23"
          src="./img/23.webp"
          srcset="./img/half/23.webp 480w, ./img/23.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
          alt="laziness"
        />
      </div>,
    <div key="22_5_2">
      <Image key="22_5_2" 
      src="./img/22_5_2.webp" 
        srcset="./img/half/22_5_2.webp 480w, ./img/22_5_2.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
      alt="casco" />
    </div>,
    <div key="23_5">
      <Video key="23_5" src="./img/23_5.mp4" poster="./img//half/23_5.webp" />
    </div>,
    <div key="24_5">
      <Image key="24_5" 
      src="./img/24_5.webp" 
         srcset="./img/half/24_5.webp 480w, ./img/24_5.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
      alt="military collection" />
    </div>,
    <div key="26_5">
      <Video key="26_5" src="./img/26_5.mp4" poster="./img//half/26_5.webp" />
    </div>,
    <div key="24">
      <Image key="24" 
      src="./img/24.webp" 
               srcset="./img/half/24.webp 480w, ./img/24.webp 1080w"
          sizes="(max-width: 480px) 100vw, 50vw"
      alt="wu tang" />
    </div>,
    <div key="25">
      <Video key="25" src="./img/25.mp4" poster="./img/25.webp" />
    </div>,

    <div key="26">
      <Video key="26" src="./img/26.mp4" poster="./img//half/26.webp" />
    </div>,
  ];

  let Thumbnail = [
    <div key="1">
      <img alt="intro" src="../img/half/1.webp" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="2">
      <img src="../img/half/2.webp" alt="nike reform" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="3">
      <img src="../img/half/3.webp" alt="cremaster" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="4">
      <img
        src="../img/half/4.webp"
        alt="marble player"
        onLoad={() => setLoaded(true)}
      />
    </div>,
    <div key="5">
      <img src="../img/half/5.webp" alt="danja guznz" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="6">
      <img src="../img/half/6.webp" alt="wiki" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="7">
      <img src="../img/half/7.webp" alt="wet paint" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="8">
      <img
        src="../img/half/8.webp"
        alt="label collection"
        onLoad={() => setLoaded(true)}
      />
    </div>,
    <div key="9">
      <img src="../img/half/9.webp" alt="freka" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="10">
      <img src="../img/half/10.webp" alt="ethos" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="11">
      <img
        src="../img/half/11.webp"
        alt="dhl x casetify"
        onLoad={() => setLoaded(true)}
      />
    </div>,
    <div key="12">
      <img src="../img/half/12.webp" alt="bristol" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="13">
      <img src="../img/half/13.webp" alt="ar" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="14">
      <img src="../img/half/14.webp" alt="web paint" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="14_5">
      <img src="../img/half/14_5.webp" alt="doom" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="15">
      <img
        src="../img/half/15.webp"
        alt="yohji yamamoto"
        onLoad={() => setLoaded(true)}
      />
    </div>,
    <div key="16">
      <img src="../img/half/16.webp" alt="club c" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="17">
      <img src="../img/half/17.webp" alt="mre" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="18">
      <img
        src="../img/half/18.webp"
        alt="self directed designer"
        onLoad={() => setLoaded(true)}
      />
    </div>,
    <div key="19">
      <img
        src="../img/half/19.webp"
        alt="label collection"
        onLoad={() => setLoaded(true)}
      />
    </div>,
    <div key="27">
      <img
        src="../img/half/27.webp"
        alt="face filter"
        onLoad={() => setLoaded(true)}
      />
    </div>,
    <div key="20_5">
      <img src="../img/half/20_5.webp" alt="actress" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="20">
      <img src="../img/half/20.webp" alt="gosh" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="21">
      <img src="../img/half/21.webp" alt="mask" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="22">
      <img src="../img/half/22.webp" alt="nbdknw" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="22_5">
      <img src="../img/half/22_5.webp" alt="nbdknw" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="23">
      <img src="../img/half/23.webp" alt="laziness" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="22_5_2">
      <img src="../img/half/22_5_2.webp" alt="casco" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="23_5">
      <img src="../img/half/23_5.webp" alt="hidden" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="24_5">
      <img
        src="../img/half/24_5.webp"
        alt="military collection"
        onLoad={() => setLoaded(true)}
      />
    </div>,
    <div key="26_5">
      <img src="../img/half/26_5.webp" alt="dhl" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="24">
      <img src="../img/half/24.webp" alt="wu tang" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="25">
      <img src="../img/half/25.webp" alt="louvre" onLoad={() => setLoaded(true)} />
    </div>,
    <div key="26">
      <img src="../img/half/26.webp" alt="pikachu" onLoad={() => setLoaded(true)} />
    </div>,
  ];

  let Mobile = [
    <div key="1">
      <Video key="1" src="./img/mobile/1.mp4" poster="./img/mobile/1.webp" />
    </div>,
    <div key="2">
      <Video key="2" src="./img/mobile/2.mp4" poster="./img/mobile/2.webp" />
    </div>,
    <div key="3">
      <Image key="3" src="./img/mobile/3.webp" alt="cremaster" />
    </div>,
    <div key="4">
      <Video key="4" src="./img/mobile/4.mp4" poster="./img/mobile/4.webp" />
    </div>,
    <div key="5">
      <Image key="5" src="./img/mobile/5.webp" alt="danja guznz" />
    </div>,
    <div key="6">
      <Image key="6" src="./img/mobile/6.webp" alt="wiki" />
    </div>,
    <div key="7">
      <Image key="7" src="./img/mobile/7.webp" alt="wet paint" />
    </div>,
    <div key="8">
      <Image key="8" src="./img/mobile/8.webp" alt="label collection" />
    </div>,
    <div key="9">
      <Image key="9" src="./img/mobile/9.webp" alt="freka" />
    </div>,
    <div key="10">
      <Image key="10" src="./img/mobile/10.webp" alt="ethos" />
    </div>,
    <div key="11">
      <Video key="11" src="./img/mobile/11.mp4" poster="./img/mobile/11.webp" />
    </div>,
    <div key="12">
      <Image key="12" src="./img/mobile/12.webp" alt="bristol" />
    </div>,
    <div key="13">
      <Video key="13" src="./img/mobile/13.mp4" poster="./img/mobile/13.webp" />
    </div>,
    <div key="14">
      <Video key="14" src="./img/mobile/14.mp4" poster="./img/mobile/14.webp" />
    </div>,
    <div key="14_5">
      <Image key="14_5" src="./img/mobile/14_5.webp" alt="doom" />
    </div>,
    <div key="15">
      <Image key="15" src="./img/mobile/15.webp" alt="yohji yamamoto" />
    </div>,
    <div key="16">
      <Image key="16" src="./img/mobile/16.webp" alt="club c" />
    </div>,
    <div key="17">
      <Image key="17" src="./img/mobile/17.webp" alt="mre" />
    </div>,
    <div key="18">
      <Video key="18" src="./img/mobile/18.mp4" poster="./img/mobile/18.webp" />
    </div>,

    <div key="19">
      <Image key="19" src="./img/mobile/19.webp" alt="label collection" />
    </div>,
    <div key="27">
      <Video key="27" src="./img/mobile/27.mp4" poster="./img/mobile/27.webp" />
    </div>,
    <div key="20_5">
      <Video
        key="20_5"
        src="./img/mobile/20_5.mp4"
        poster="./img/mobile/20_5.webp"
      />
    </div>,
    <div key="20">
      <Video key="20" src="./img/mobile/20.mp4" poster="./img/mobile/20.webp" />
    </div>,
    <div key="21">
      <Video key="21" src="./img/mobile/21.mp4" poster="./img/mobile/21.webp" />
    </div>,
    <div key="22">
      <Image key="22" src="./img/mobile/22.webp" alt="nbdknw" />
    </div>,
    <div key="22_5">
      <Image key="22_5" src="./img/mobile/22_5.webp" alt="nbdknw" />
    </div>,

    <div key="23">
      <Image key="23" src="./img/mobile/23.webp" alt="laziness" />
    </div>,
    <div key="22_5_2">
      <Image key="22_5_2" src="./img/mobile/22_5_2.webp" alt="casco" />
    </div>,
    <div key="23_5">
      <Video
        key="23_5"
        src="./img/mobile/23_5.mp4"
        poster="./img/mobile/23_5.webp"
      />
    </div>,
    <div key="24_5">
      <Image key="24_5" src="./img/mobile/24_5.webp" alt="military collection" />
      ,
    </div>,
    <div key="26_5">
      <Video
        key="26_5"
        src="./img/mobile/26_5.mp4"
        poster="./img/mobile/26_5.webp"
      />
    </div>,
    <div key="24">
      <Image key="24" src="./img/mobile/24.webp" alt="wu tang" />,
    </div>,
    <div key="25">
      <Video key="25" src="./img/mobile/25.mp4" poster="./img/mobile/25.webp" />
    </div>,

    <div key="26">
      <Video key="26" src="./img/mobile/26.mp4" poster="./img/mobile/26.webp" />
    </div>,
  ];

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  const isMobile = width <= 768;

  useEffect(() => {
    const node = document.querySelector(".carousel-main");

    if (!node) return;

    let timeout;

    const handleMouseMove = () => {
      node.classList.remove("fadenav");
      clearTimeout(timeout);
      timeout = setTimeout(addFadeClass, 3000);
    };

    const addFadeClass = () => {
      node.classList.add("fadenav");
    };

    setTimeout(() => {
      node.style.opacity = 1;
    }, 1000);

    setTimeout(() => {
      node.classList.add("fadenav");
    }, 4000);

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(timeout);
      node.classList.remove("fadenav");
    };
  }, []);

  const flickityOptions = {
    asNavFor: ".carousel-main",
    cellAlign: "center",
    wrapAround: true,
    pageDots: false,
    prevNextButtons: false,
    // lazyLoad: true,
    // lazyLoadOnce: true,
    // lazyLoadVideo: true,
    on: {
      pointerDown: () => {
        // flRef.current.flkty.pausePlayer();
        flRef2.current.flkty.pausePlayer();
      },
      pointerUp: () => {
        if (!flRef2.current?.flkty) return;

        setTimeout(() => {
          if (!flRef2.current.flkty.isPaused) {
            flRef2.current.flkty.next();
          }
          flRef2.current.flkty.playPlayer();
        }, 100);
      },
      select: () => {
        if (!flRef.current?.flkty) return;

        // const flickity = flRef.current.flkty;
        // console.log (flRef.current.flkty)
        if (flRef.current.flkty) {
          const video =
            flRef.current.flkty.selectedElement.querySelector("video");
          // console.log (video)
          if (video) {
            // Show loading animation.
            var playPromise = video.play();

            if (playPromise !== undefined) {
              video.currentTime = 0;
              playPromise
                .then((_) => {
                  // Automatic playback started!
                  // Show playing UI.
                })
                .catch((error) => {
                  // Auto-play was prevented
                  // Show paused UI.
                });
            }
            // video.currentTime = 0;
            // video.play();
          }
        }
      },
    },
    destroy: () => {
      flRef.current.flkty.destroy();
    },
  };
  const flickityOptions2 = {
     asNavFor: ".carousel-main",
    cellAlign: "center",
    autoPlay: 600,
    wrapAround: true,
    pageDots: false,
    prevNextButtons: false,
    // lazyLoad: true,
    // bgLazyLoad: true,
    freeScroll: true,
    setGallerySize: false,
    on: {
      pointerDown: () => {
        if (!flRef2.current?.flkty) return;

        flRef2.current.flkty.pausePlayer();
      },
      pointerUp: () => {
        if (!flRef2.current?.flkty) return;

        setTimeout(() => {
          flRef2.current.flkty.playPlayer();
        }, 100);
      },
      ready: () => {
        let requestId = null;

        const handleResize = () => {
          if (!flRef2.current?.flkty) return;

          if (requestId) {
            cancelAnimationFrame(requestId);
          }

          requestId = requestAnimationFrame(() => {
            flRef2.current.flkty.stopPlayer();
            setTimeout(() => {
              flRef2.current.flkty.stopPlayer();
              flRef2.current.flkty.reloadCells();
              flRef2.current.flkty.resize();
              flRef2.current.flkty.playPlayer();
            }, 800);

            requestId = null;
          });
        };

        window.addEventListener("resize", handleResize, { passive: true });
      },
    },
  };

  return (
    <div className={`wrapper ${isLoaded ? "loaded" : "loading"}`}>
      <Flickity
        ref={flRef}
        className={"carousel"}
        options={flickityOptions}
      >
        {isMobile ? Mobile : Main}
      </Flickity>
      <Flickity
        className="carousel-main"
        options={flickityOptions2}
        ref={flRef2}
      >
        {Thumbnail.map((thumb, index) => React.cloneElement(thumb, { onLoad: handleImageLoad, key: index }))}
      </Flickity>
    </div>
  );
};

export default Home;
